<template>
  <b-row>
    <b-col cols="12">
      <b-card-code no-body class="card-statistics">
        <b-card-body>
          <!-- MINI CARDS COM A ESTATISTICAS DO PIPELINE DE CADASTRO -->
          <b-row>
            <b-col
              v-for="item in videos"
              :key="item.icon"
              xl="3"
              sm="6"
              class="p-2"
            >
              <b-media no-body @click="player(item.vimeoId)" class="panel">
                <b-media-aside class="mr-2">
                  <b-avatar size="48" :variant="item.color">
                    <feather-icon size="24" :icon="item.icon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.title }}
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.subtitle }}
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BRow,
  BCol,
  BCardBody,
  BCardText,
  BButton,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BEmbed 
} from "bootstrap-vue";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";


export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BButton,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAvatar,
    BCardCode,
    BEmbed 
  },
  data() {
    return {
      videos: [
        {
          icon: "UserPlusIcon",
          color: "light-dark",
          title: "Cadastro Equipe",
          subtitle: `Como cadastrar corretor`,
          customClass: "mb-2 mb-xl-0",
          key: "Análise",
          vimeoId: '714962252'
        },
          {
            icon: "FolderPlusIcon",
            color: "light-dark",
            title: "Pré Cadastro",
            subtitle: `Como fazer um pré cadastro`,
            customClass: "mb-2 mb-xl-0",
            key: "Análise",
            vimeoId: '714971474'
          },
        {
          icon: "ToolIcon",
          color: "light-dark",
          title: "Lead",
          subtitle: `Conhecendo a Ferramenta`,
          customClass: "mb-2 mb-xl-0",
          key: "Análise",
          vimeoId: '714969198'
        },
        {
          icon: "PhoneOutgoingIcon",
          color: "light-dark",
          title: "Lead",
          subtitle: `Como gerenciar os leads`,
          customClass: "mb-2 mb-xl-0",
          key: "Análise",
          vimeoId: '714968283'
        },
      ],
    };
  },
  methods: {
      player(id) {
          this.$router.push({ path: `/treinamentos/${id}`})
      }
  }
};
</script>

<style>
.panel:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.5s;
}
</style>